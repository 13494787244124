export const IntroductionText = () => {
    const lines = [
        `I’ve had the incredible fortune to explore a vast array of countries and cultures, each adding its own unique flavor to my journey. My adventures have taken me from the dynamic cities of the USA and the tranquil landscapes of Canada to the vibrant islands of the Philippines, Malaysia, and Singapore. Each destination has left its mark on me, expanding my understanding of the world.`,
        `I’ve wandered through the historical wonders of Vietnam, Laos, Cambodia, Myanmar, and Thailand, immersing myself in the rich tapestry of Southeast Asia. The diverse archipelagos of Indonesia, Brunei, and Timor Leste offered their own unique experiences, while the cultural and historical treasures of China and Taiwan broadened my horizons even further.`,
        `My travels didn’t stop there. I’ve explored the energetic streets of South Korea and the timeless beauty of Japan, and I’ve been deeply moved by the spiritual landscapes of India and Nepal. Sri Lanka’s warm hospitality and Iran’s enigmatic charm, alongside the rich history of Iraq and Oman, provided me with profound insights into diverse cultures.`,
        `My journey through the Middle East continued with experiences in the UAE, Qatar, Bahrain, Jordan, and Kuwait, each offering its own blend of history and modernity. In Europe, I’ve delved into the storied past of Russia, the UK, Germany, Austria, and Hungary, and I’ve been enchanted by the elegance of France, the charm of Belgium, the beauty of the Netherlands, and the warmth of Ireland.`,
        `Central Asia beckoned with the vast landscapes and rich histories of Uzbekistan, Kyrgyzstan, and Kazakhstan, while Portugal and Tunisia added a touch of Western European and North African culture to my travels.`,
        `Every country I’ve visited is a chapter in my grand adventure, each one weaving together a rich tapestry of experiences and connections that span the globe. My passport is a testament to the incredible journey I’ve had, and each new destination has been a reminder of how much there is to learn and discover in this diverse and fascinating world.`,
    ];

    return (
        <div>
            {lines.map((line, index) => {
                const isEven = index % 2 === 0;
                return (
                    <div 
                        style={{
                            margin: '30px 0px',
                            textAlign: isEven ? 'left' : 'right',
                            color: isEven ? '#DDDDDD' : '#AAAAAA'
                        }}
                    >
                        {line}
                    </div>
                );
            })}
        </div>
    );
};